$fonte: "Poppins";

$fonte-light: #373737;
$fonte-dark: #FBFBFB;
$tecla-light: #ffffff;
$tecla-dark: #050505;
$roxo: #7878ff;
$azul: #0573bd;

$bgDisplay-1-light: #FBFBFB;
$bgDisplay-2-light: #EEE;
$bgDisplay-3-light: #E5E5E5;
$bgDisplay-1-dark: #2b2a2a;
$bgDisplay-2-dark: #1b1c1d;
$bgDisplay-3-dark: #000309;

$bgTeclado-1-light: #9EE8FF;
$bgTeclado-2-light: #79AFFF;
$bgTeclado-3-light: #5ACEFF;
$bgTeclado-4-light: #2D5FDE;
$bgTeclado-1-dark: #42869B;
$bgTeclado-2-dark: #2A7DA1;
$bgTeclado-3-dark: #224E91;
$bgTeclado-4-dark: #00123F;