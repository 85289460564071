@import "../../styles/variaveis";

.Display {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 2rem;
    color: $fonte-light;
    background: linear-gradient(166.34deg, $bgDisplay-1-light 0%, $bgDisplay-2-light 22.9%, $bgDisplay-3-light 100%);

    &.Dark {
        color: $fonte-dark;
        background: linear-gradient(166.34deg, $bgDisplay-1-dark 0%, $bgDisplay-2-dark 22.9%, $bgDisplay-3-dark 100%);

        .container .histor:hover {
            color: $azul;
        }
    }
}

.container {
    position: relative;
    height: 14rem;
    width: 18rem;
    font-family: $fonte;
    z-index: 2000;

}

.toggle {
    width: 6rem;
    margin: 0 auto;
    margin-top: 2rem;
}

.btn-history {
    position: absolute;
    right: 1rem;
    top: .25rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    box-sizing: border-box;
    border-radius: 50%;
    color: inherit;
}

.history {
    position: absolute;
    left: 0;
    top: 0;
    width: 1.5rem;
    height: 1.5rem;
    color: inherit;

    &:hover {
        color: $roxo;
    }
}

.expressao {
    position: absolute;
    bottom: 5.2rem;
    right: 1rem;
    padding: 0;
    height: 1.1rem;
    width: 15rem;
    text-align: right;
    font-size: 1.1rem;
    letter-spacing: .2rem;
    color: inherit;
    opacity: 70%;
    font-weight: 500;
    background-color: transparent;
    border: none;
    cursor: default;

    &:focus {
        outline: none;
    }
}

.igual {
    position: absolute;
    bottom: 2.5rem;
    left: 2rem;
    color: inherit;
    font-weight: 600;
    font-size: 1.3rem;
    cursor: default;
}

.resultado {
    position: absolute;
    right: 1rem;
    bottom: 2.5rem;
    color: inherit;
    font-weight: 600;
    font-size: 2rem;
    box-sizing: border-box;
    background-color: transparent;
    width: 12rem;
    border: none;
    text-align: right;
    height: 2rem;
    cursor: default;

    &:focus {
        outline: none;
    }
}