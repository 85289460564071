@import "../../styles/variaveis";

.Tecla {
    position: relative;
    width: 3.25rem;
    margin: 0.32rem .375rem;
    height: 3.25rem;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font-family: $fonte;
    font-weight: 600;
    font-size: 1.6rem;
    box-sizing: border-box;
    transition: ease-in-out 100ms;
    background: rgba($tecla-light, .4);
    color: $fonte-light;

    &.Dark {
        color: $fonte-dark;
        background: rgba($tecla-dark, .4);

        &:hover {
            background: rgba($tecla-dark, .6);
        }

        &:active {
            background: rgba($azul, .5);
        }
    }

    &:hover {
        background: rgba($tecla-light, 0.7);
    }

    &:active {
        background: rgba($roxo, 0.5);
    }
}

.topBar .Tecla {
    &:nth-child(1) {
        border-radius: 3.25rem 0 0 3.25rem;
        margin: 0;
        width: 3.75rem;
    }

    &:nth-child(2) {
        border-radius: 0;
        margin: 0;
        width: 3.75rem;
    }

    &:nth-child(3) {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 3.25rem 3.25rem 0;
        margin: 0;
        width: 3.75rem;
    }
}

.rightSideBar .Tecla {
    border-radius: 0;
    margin: 0;
    height: 3.75rem;

    &:first-child {
        border-radius: 3.25rem 3.25rem 0 0;
    }

    &:last-child {
        border-radius: 0 0 3.25rem 3.25rem;
    }

    &:last-child:hover {
        background: rgba($tecla-light, 0.4);
    }

    &.Dark {
        &:last-child:hover {
            background: rgba($tecla-dark, .4);
        }

        .igual {
            color: $fonte-dark;
            background: rgba($tecla-dark, .25);

            &:hover {
                background: rgba($tecla-dark, .6);
            }

            &:active {
                background: rgba($azul, .8);
            }
        }
    }

    .igual {
        cursor: pointer;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3.25rem;
        width: 3.25rem;
        color: $fonte-light;
        background: rgba($tecla-light, 0.4);

        &:hover {
            background: rgba($tecla-light, 0.9);
        }

        &:active {
            background: rgba($roxo, 0.5);
        }
    }
}